import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import {
  Comment,
  CommentBloomFilterResponse,
  CommentReplies,
  CommentResponse,
  CommenterList,
  CommenterListResponse,
  CommentsArrayResponse,
  CommentsRepliesArrayResponse,
  DeleteCommentResponse
} from './api.comments.model';

export class CommentsApi {
  private apiUrl: string = environment.baseURL;
  COMMENTS = '/comment/comments';
  constructor(
    public readonly httpClient: HttpClient,
    public config: ApiClientConfig
  ) { }

  getCommentsByPostId(object_id: string, pageNum: number, pageSize: number): Observable<Comment[]> {
    return this.httpClient
      .get<CommentsArrayResponse>(`${this.apiUrl}${this.COMMENTS}/${object_id}?pageNum=${pageNum}&pageSize=${pageSize}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: CommentsArrayResponse) => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getCommentObjectById(object_id: string): Observable<Comment> {
    return this.httpClient.get<CommentResponse>(`${this.apiUrl}${this.COMMENTS}/${object_id}/object`).pipe(
      timeout(this.config.responseTimeout),
      map((res: CommentResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getCommentsByParentId(parentId: string, pageNum: number, pageSize: number): Observable<CommentReplies> {
    return this.httpClient
      .get<CommentsRepliesArrayResponse>(
        `${this.apiUrl}${this.COMMENTS}/${parentId}/replies?pageNum=${pageNum}&pageSize=${pageSize}&referenceType=TIME_ASC`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: CommentsRepliesArrayResponse) => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  postComment(body: any): Observable<Comment> {
    return this.httpClient.post<CommentResponse>(`${this.apiUrl}${this.COMMENTS}`, body).pipe(
      timeout(this.config.responseTimeout),
      map((res: CommentResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  deleteComment(object_id: string): Observable<any> {
    return this.httpClient.delete<DeleteCommentResponse>(`${this.apiUrl}${this.COMMENTS}/${object_id}`).pipe(
      timeout(this.config.responseTimeout),
      map((res: DeleteCommentResponse) => {
        return res.success;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  putComment(comment: Comment): Observable<Comment> {
    return this.httpClient.put<CommentResponse>(`${this.apiUrl}${this.COMMENTS}/${comment.id}`, comment).pipe(
      timeout(this.config.responseTimeout),
      map((res: CommentResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getCommenterListByEntityId(object_id: string): Observable<CommenterList> {
    return this.httpClient.get<CommenterListResponse>(`${this.apiUrl}${this.COMMENTS}/${object_id}/people`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getCommentBloomFilter(): Observable<string> {
    return this.httpClient.get<CommentBloomFilterResponse>(`${this.apiUrl}${this.COMMENTS}/me/comments`).pipe(
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getCommentList(postIdList: string[]): Observable<any> {
    const body = {
      post_id: postIdList
    };
    return this.httpClient
      .post<any>(`${this.apiUrl}${this.COMMENTS}/posts?pageNum=0&pageSize=4`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
