import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent {
  @Input() titleHeader: string;
  @Input() description: string;
  @Input() visible = false;
  @Input() visibleDialogConfirm = false;
  @Input() resetForm: () => void;

  @Output() keepEditing = new EventEmitter<void>();

  confirmCloseActions = {
    discard: 'DISCARD',
    keepEditing: 'KEEP_EDITING'
  };

  confirmDialog(action: string): void {
    if (action === this.confirmCloseActions.keepEditing) {
      this.keepEditing.emit();
    } else if (action === this.confirmCloseActions.discard) {
      this.resetForm();
    }
  }
}
