import { Injectable } from "@angular/core";
import { BRAND_SOCTRIP_MASTER, BRAND_SOCTRIP_STAR } from "@app/shared/models/user-badge.model";
// import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class UserBadgeService {
    getUserBadge(type: any){
        
        return type.user_brands?.map((res: any) => {
            switch(res.brand){
                case 'SOCTRIP_STAR':
                    return BRAND_SOCTRIP_STAR.find(ele => ele.type === res.rank_brand);
                case 'SOCTRIP_MASTER':
                    return BRAND_SOCTRIP_MASTER.find(ele => ele.type === res.rank_brand);
                default:
                    return res;

            }
        });
    }
  }