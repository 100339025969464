import {
  GroupRule,
  PostPending,
  UpdateMemberAccept,
  UpdateMemberBan,
  UpdateMemberDecline,
  UserRole,
  getDetailGroup,
  getMemberGroup,
  getPendingGroupMember,
  groupAvatarModel,
  groupBannerModel
} from '@app/lib/api/group/api.group.model';
import { GroupDetailModel, ModalGroupAbout } from '@app/shared/models/group.model';
import { Post, PostGroup } from '@app/shared/models/post';
import { createAction, emptyProps, props } from '@ngrx/store';
import { PostIdsList } from '../components/group-tab/group-settings/member-requests/member-requests.component';
import { GroupPost } from '../components/group-tab/group-timeline/group-timeline.component';
import { UploadAvatarModel, UploadBannerModel } from '../components/group-tab/group-view/group-view.component';

export enum postGroupTypes {
  getPostGroup = '[Group] fetch',
  getPostGroupSuccess = '[Group] fetch success',
  fetchFailure = '[Group] fetch failure',

  createPostGroup = '[Group] create new post',
  createPostGroupSuccess = '[Group] create new group post',
  createPostGroupFailure = '[Group] create new group post failure',

  // info detail group
  loadInfoGroupDetails = '[Group] load info detail group',
  loadInfoGroupDetailsSuccess = '[Group] load info detail group success',
  loadInfoGroupDetailsFailure = '[Group] load info detail group failure',
  resetInfoGroupDetails = '[Group] load reset info group details',
  loadInfoGroup = '[Group] load info group',

  // role group
  loadRoleGroup = '[Group] load group user role',
  loadRoleGroupSuccess = '[Group] load group user role success',
  loadRoleGroupFailure = '[Group] load group user role failure',

  // member group
  loadMemberGroup = '[Group] load member group',
  loadMoreMemberGroup = '[Group] load more member group',
  loadMemberGroupSuccess = '[Group] load member group success',
  loadMoreMemberGroupSuccess = '[Group] load more member group success',
  loadMemberGroupFailure = '[Group] load member group failure',
  resetMemberGroup = '[Group] reset member group',

  // member pending group
  loadMemberPendingGroup = '[Group] load member pending group',
  loadMemberPendingGroupSuccess = '[Group] load member pending group success',
  loadMemberPendingGroupFailure = '[Group] load member pending group failure',
  resetPendingGroup = '[Group] reset member pending group',

  // member accept group
  loadMemberAcceptGroup = '[Group] load member accept group',
  loadMemberAcceptGroupSuccess = '[Group] load member accept group success',
  loadMemberAcceptGroupFailure = '[Group] load member accept group failure',

  // member decline group
  loadMemberDeclineGroup = '[Group] load member decline group',
  loadMemberDeclineGroupSuccess = '[Group] load member decline group success',
  loadMemberDeclineGroupFailure = '[Group] load member decline group failure',

  // member ban group
  loadMemberBanGroup = '[Group] load member ban group',
  loadMemberBanGroupSuccess = '[Group] load member ban group success',
  loadMemberBanGroupFailure = '[Group] load member ban group failure',

  // view bai post group
  loadPostGroup = '[Group] view post group',
  loadPostGroupSuccess = '[Group] view post group success',
  loadPostGroupFailure = '[Group] view post group failure',
  loadCreateGroupPostSuccess = '[Group] create post group success',
  resetPostGroup = '[Group] reset post group',

  // tạo bài post group
  groupPostCreate = '[Group] group post create',
  groupPostCreateSuccess = '[Group] group post create success',
  groupPostCreateFailure = '[Group] group post create failure',

  // upload Avatar group
  uploadGroupAvatar = '[Group] upload avatar group',
  uploadAvatarGroupSuccess = '[Group] upload avatar group success',
  uploadAvatarGroupFailure = '[Group] upload avatar group failure',

  // upload Banner group
  uploadBannerGroup = '[Group] upload banner group',
  uploadBannerGroupSuccess = '[Group] upload banner group success',
  uploadBannerGroupFailure = '[Group] upload banner group failure',

  // Delete post of group
  deleteGroupPost = '[Group] delete a post on the group',
  deleteGroupPostSuccess = '[Group] delete a post on the group success',
  deleteGroupPostFailed = '[Group] delete a post on the group failed',
  deleteGroupPinnedPost = '[Group] delete a pinned on the group',

  // pin post of group
  unpinPost = '[Group] unpin a post on the group',
  unpinPostSuccess = '[Group] unpin a post on the group success',
  pinPost = '[Group] pin a post on the group',
  pinPostSuccess = '[Group] pin a post on the group success'
}

// Delete post for Group
export const deleteGroupPost = createAction(postGroupTypes.deleteGroupPost, props<{ id: string; isPinned: boolean }>());
export const deleteGroupPostSuccess = createAction(postGroupTypes.deleteGroupPostSuccess, props<{ id: string }>());
export const deleteGroupPostFailed = createAction(postGroupTypes.deleteGroupPostFailed, props<{ message: string }>());
export const deleteGroupPinnedPost = createAction(postGroupTypes.deleteGroupPinnedPost, emptyProps);

// Pin post for Group

export const unpinPost = createAction(
  postGroupTypes.deleteGroupPinnedPost,
  props<{ isPinned: boolean; postId: string }>()
);
export const unpinPostSuccess = createAction(postGroupTypes.deleteGroupPinnedPost, emptyProps);

export const pinPost = createAction(
  postGroupTypes.pinPost,
  props<{ postId: string; isPinned: true; post: Post; index: number }>()
);
export const pinPostSuccess = createAction(postGroupTypes.pinPostSuccess, props<{ index: number; post: Post }>());

// get post group
export const fetch = createAction(postGroupTypes.getPostGroup, emptyProps);
export const fetchSuccess = createAction(postGroupTypes.getPostGroupSuccess, props<{ postGroups: PostGroup[] }>());

//create post group
export const createPostGroup = createAction(postGroupTypes.createPostGroup, props<{ post: PostGroup }>());
export const createPostGroupSuccess = createAction(postGroupTypes.createPostGroupSuccess, props<{ post: PostGroup }>());
export const createPostGroupFailure = createAction(postGroupTypes.createPostGroupFailure, props<{ message: string }>());

// info detail group
export const loadInfoGroupDetails = createAction(postGroupTypes.loadInfoGroupDetails, props<{ groupId: string }>());
export const loadInfoGroupDetailsSuccess = createAction(
  postGroupTypes.loadInfoGroupDetailsSuccess,
  props<{ InfoGroupDetail: getDetailGroup }>()
);
export const loadInfoGroupDetailsFailure = createAction(
  postGroupTypes.loadInfoGroupDetailsFailure,
  props<{ message: string }>()
);

export const loadInfoGroup = createAction(postGroupTypes.loadInfoGroup, props<{ groupId: string; admin: boolean }>());

export const resetInfoGroupDetails = createAction(postGroupTypes.resetInfoGroupDetails, emptyProps);

//role group
export const loadRoleGroup = createAction(postGroupTypes.loadRoleGroup, props<{ groupId: string }>());
export const loadRoleGroupSuccess = createAction(postGroupTypes.loadRoleGroupSuccess, props<{ RoleGroup: UserRole }>());
export const loadRoleGroupFailure = createAction(postGroupTypes.loadRoleGroupFailure, props<{ message: string }>());

// member group
export const loadMemberGroup = createAction(
  postGroupTypes.loadMemberGroup,
  props<{
    groupId: string;
    role?: string | null;
    textSearch?: string | null;
    pageNum?: number;
    pageSize?: number;
  }>()
);

export const loadMoreMemberGroup = createAction(
  postGroupTypes.loadMoreMemberGroup,
  props<{ groupId: string; role: string; pageNum: number }>()
);
export const loadMemberGroupSuccess = createAction(
  postGroupTypes.loadMemberGroupSuccess,
  props<{ memberGroup: getMemberGroup }>()
);

export const loadMoreMemberGroupSuccess = createAction(
  postGroupTypes.loadMoreMemberGroupSuccess,
  props<{ memberGroup: getMemberGroup }>()
);
export const loadMemberGroupFailure = createAction(postGroupTypes.loadMemberGroupFailure, props<{ message: string }>());
export const resetMemberGroup = createAction(postGroupTypes.resetMemberGroup, props<{ message: string }>());

// member pending group
export const loadMemberPendingGroup = createAction(postGroupTypes.loadMemberPendingGroup, props<{ groupId: string }>());
export const loadMemberPendingGroupSuccess = createAction(
  postGroupTypes.loadMemberPendingGroupSuccess,
  props<{ memberPendingGroup: getPendingGroupMember }>()
);
export const resetPendingGroup = createAction(
  postGroupTypes.resetPendingGroup,
  props<{ memberPendingGroup: getPendingGroupMember }>()
);
export const loadMemberPendingGroupFailure = createAction(
  postGroupTypes.loadMemberPendingGroupFailure,
  props<{ message: string }>()
);

// member accept group
export const loadMemberAcceptGroup = createAction(
  postGroupTypes.loadMemberAcceptGroup,
  props<{ groupId: string; postIdsList: PostIdsList }>()
);
export const loadMemberAcceptGroupSuccess = createAction(
  postGroupTypes.loadMemberAcceptGroupSuccess,
  props<{ ids: string[]; memberAcceptGroup: UpdateMemberAccept }>()
);
export const loadMemberAcceptGroupFailure = createAction(
  postGroupTypes.loadMemberAcceptGroupFailure,
  props<{ message: string }>()
);

// member decline group
export const loadMemberDeclineGroup = createAction(
  postGroupTypes.loadMemberDeclineGroup,
  props<{ groupId: string; postIdsList: PostIdsList }>()
);
export const loadMemberDeclineGroupSuccess = createAction(
  postGroupTypes.loadMemberDeclineGroupSuccess,
  props<{ ids: string[]; memberDeclineGroup: UpdateMemberDecline }>()
);
export const loadMemberDeclineGroupFailure = createAction(
  postGroupTypes.loadMemberDeclineGroupFailure,
  props<{ message: string }>()
);

// member ban group
export const loadMemberBanGroup = createAction(
  postGroupTypes.loadMemberBanGroup,
  props<{ groupId: string; user_id: string }>()
);
export const loadMemberBanGroupSuccess = createAction(
  postGroupTypes.loadMemberBanGroupSuccess,
  props<{ id: string | null; memberBanGroup: UpdateMemberBan }>()
);
export const loadMemberBanGroupFailure = createAction(
  postGroupTypes.loadMemberBanGroupFailure,
  props<{ message: string }>()
);

//view bai post group
export const loadPostGroup = createAction(
  postGroupTypes.loadPostGroup,
  props<{ groupId: string; params: GroupPost }>()
);
export const loadPostGroupSuccess = createAction(postGroupTypes.loadPostGroupSuccess, props<{ getPost: any }>());
export const loadPostGroupFailure = createAction(postGroupTypes.loadPostGroupFailure, props<{ message: string }>());
export const loadCreateGroupPostSuccess = createAction(
  postGroupTypes.loadCreateGroupPostSuccess,
  props<{ post: Post }>()
);
export const resetPostGroup = createAction(postGroupTypes.resetPostGroup, emptyProps);

//view bai post group
export const groupPostCreate = createAction(postGroupTypes.groupPostCreate, props<{ newsPostGroup: any }>());
export const groupPostCreateSuccess = createAction(
  postGroupTypes.groupPostCreateSuccess,
  props<{ getPostGroup: any }>()
);
export const groupPostCreateFailure = createAction(postGroupTypes.groupPostCreateFailure, props<{ message: string }>());

//upload avatar group
export const uploadGroupAvatar = createAction(postGroupTypes.uploadGroupAvatar, props<{ param: UploadAvatarModel }>());
export const uploadAvatarGroupSuccess = createAction(
  postGroupTypes.uploadAvatarGroupSuccess,
  props<{ newAvatarData: groupAvatarModel }>()
);
export const uploadAvatarGroupFailure = createAction(
  postGroupTypes.uploadAvatarGroupFailure,
  props<{ message: string }>()
);

//upload banner group
export const uploadBannerGroup = createAction(postGroupTypes.uploadBannerGroup, props<{ param: UploadBannerModel }>());
export const uploadBannerGroupSuccess = createAction(
  postGroupTypes.uploadBannerGroupSuccess,
  props<{ newBannerData: groupBannerModel }>()
);
export const uploadBannerGroupFailure = createAction(
  postGroupTypes.uploadBannerGroupFailure,
  props<{ message: string }>()
);

//Pending Post
export enum pendingPostTypes {
  getPendingPost = '[Posts] fetch',
  getPendingPostSuccess = '[Posts] fetch success',
  fetchPendingFailure = '[Group] fetch failure',
  filterPendingPost = '[Group] Filter Pending Post',
  resetPendingPost = '[Group] reset Pending Post',

  approveByPostIds = '[Post] decline by post ids',
  approveByPostIdsSuccess = '[Post] decline by post ids success',
  approveByPostIdsFailure = '[Post] decline by post ids failure'
}

export const fetchPendingPost = createAction(
  pendingPostTypes.getPendingPost,
  props<{ groupID: string | null; params: GroupPost }>()
);
export const resetPendingPost = createAction(pendingPostTypes.resetPendingPost, props<{ posts: PostPending[] }>());
export const fetchPendingPostSuccess = createAction(
  pendingPostTypes.getPendingPostSuccess,
  props<{ posts: PostPending[] }>()
);
export const filterPendingPost = createAction(pendingPostTypes.filterPendingPost, props<{ postIds: string[] }>());

export const manageByPostIds = createAction(
  pendingPostTypes.approveByPostIds,
  props<{ groupID: string; action: 'decline' | 'accept'; ids: string[] }>()
);
export const manageByPostIdsSuccess = createAction(
  pendingPostTypes.approveByPostIdsSuccess,
  props<{ ids: string[] }>()
);
export const manageByPostIdsFailure = createAction(
  pendingPostTypes.approveByPostIdsFailure,
  props<{ message: string }>()
);

export const showToast = createAction(
  '[Toast] show toast',
  props<{ severity: 'success' | 'error'; detail: string; life: number }>()
);

// group detail
export enum groupDetailTypes {
  getGroupDetail = '[GroupDetail] get group detail',
  getGroupDetailSuccess = '[GroupDetail] get group detail success',
  getGroupDetailFailure = '[GroupDetail] get group detail failure',

  editGroupDetail = '[GroupDetail] edit group detail',
  editGroupDetailSuccess = '[GroupDetail] edit group detail success',
  editGroupDetailFailure = '[GroupDetail] edit group detail failure'
}

export const getGroupDetail = createAction(groupDetailTypes.getGroupDetail, props<{ groupId: string }>());
export const getGroupDetailSuccess = createAction(
  groupDetailTypes.getGroupDetailSuccess,
  props<{ groupDetail: ModalGroupAbout }>()
);
export const getGroupDetailFailure = createAction(groupDetailTypes.getGroupDetailFailure, props<{ message: string }>());

export const editGroupDetail = createAction(
  groupDetailTypes.editGroupDetail,
  props<{ groupDetail: GroupDetailModel }>()
);
export const editGroupDetailSuccess = createAction(
  groupDetailTypes.editGroupDetailSuccess,
  props<{ groupDetail: GroupDetailModel }>()
);
export const editGroupDetailFailure = createAction(
  groupDetailTypes.editGroupDetailFailure,
  props<{ message: string }>()
);

// group rules
export enum groupRuleTypes {
  getGroupRule = '[GroupRule] get group rule',
  getGroupRuleSuccess = '[GroupRule] get group rule success',
  getGroupRuleFailure = '[GroupRule] get group rule failure',

  editGroupRule = '[GroupRule] edit group rule',
  editGroupRuleSuccess = '[GroupRule] edit group rule success',
  editGroupRuleFailure = '[GroupRule] edit group rule failure'
}

export const getGroupRule = createAction(groupRuleTypes.getGroupRule, props<{ groupId: string }>());
export const getGroupRuleSuccess = createAction(groupRuleTypes.getGroupRuleSuccess, props<{ groupRule: GroupRule }>());
export const getGroupRuleFailure = createAction(groupRuleTypes.getGroupRuleFailure, props<{ message: string }>());

export const editGroupRule = createAction(
  groupRuleTypes.editGroupRule,
  props<{ groupId: string | null; content: string }>()
);
export const editGroupRuleSuccess = createAction(
  groupRuleTypes.editGroupRuleSuccess,
  props<{ groupRule: GroupRule }>()
);
export const editGroupRuleFailure = createAction(groupRuleTypes.editGroupRuleFailure, props<{ message: string }>());
