<p-dialog
  header="{{ (oldDataEvent ? 'EVENT.EDIT_EVENT' : 'EVENT.CREATE_EVENT') | translate }}"
  [(visible)]="visible"
  [modal]="true"
  [draggable]="false"
  (onHide)="handleCloseModal()"
  [resizable]="false"
  [dismissableMask]="true"
  styleClass="w-[792px] rounded-lg border border-palette-gray-200 bg-palette-base-white shadow-sm p-0"
  class="custom-event custom-scroll">
  <div *ngIf="visible">
    <div (click)="handleCloseDialog()">
      <div class="relative w-full">
        <div>
          <img
            *ngIf="!oldDataEvent?.avatar && !selectedImage; else newBackGround"
            class="h-[377px] w-full object-cover"
            [src]="defaultImageUrl"
            alt="Avatar Icon"
            (click)="showBannerGallery = true" />
          <ng-template #newBackGround>
            <img
              class="h-[377px] w-full object-cover"
              [src]="backgroundUrl"
              alt=""
              (click)="showBannerGallery = true" />
          </ng-template>
        </div>
        <div
          class="absolute top-4 right-4 bg-palette-gray-900/20 hover:bg-palette-gray-900/40 transition-colors w-7 h-7 p-1.5 flex items-center justify-center rounded-full cursor-pointer"
          (click)="fileInput.click()">
          <i class="sctr-icon-edit-03 text-base/4 text-palette-base-white"></i>
        </div>
        <input type="file" (change)="onFileSelected($event)" #fileInput style="display: none" />
      </div>
      <div class="flex flex-col p-4 md:p-6 pr-2 md:pr-4 gap-4">
        <div class="flex flex-col gap-1.5">
          <span class="flex font-medium text-sm text-palette-gray-700">{{ 'EVENT.EVENT_TYPE' | translate }}</span>
          <div class="flex gap-4 md:gap-6 w-full h-[52px]">
            <label
              for="1"
              class="flex w-[50%] p-4 rounded-lg font-medium text-sm gap-4 cursor-pointer"
              [ngClass]="{
                'bg-branding-primary-50 text-branding-primary-800 border-2 border-branding-primary-600':
                  selectedOption === '1',
                'border-slate-300 border text-palette-gray-700': selectedOption === '2'
              }">
              <input
                type="radio"
                id="1"
                name="radioOption"
                [disabled]="isSubmitting"
                value="1"
                [(ngModel)]="selectedOption"
                (ngModelChange)="checkAllFields()" />
              {{ 'EVENT.OFFLINE' | translate }}
            </label>

            <label
              for="2"
              class="flex w-[50%] p-4 rounded-lg border font-medium text-sm gap-4 cursor-pointer"
              [ngClass]="{
                'bg-branding-primary-50 text-branding-primary-800 border-2 border-branding-primary-600':
                  selectedOption === '2',
                'border-slate-300 border text-palette-gray-700': selectedOption === '1'
              }">
              <input
                type="radio"
                id="2"
                name="radioOption"
                [disabled]="isSubmitting"
                value="2"
                [(ngModel)]="selectedOption"
                (ngModelChange)="checkAllFields()" />
              {{ 'EVENT.ONLINE' | translate }}
            </label>
          </div>
        </div>
        <div class="flex flex-col gap-1.5">
          <span class="flex font-medium text-sm text-palette-gray-700">
            {{ 'EVENT.EVENT_NAME' | translate }}
            <p class="ml-1 text-system-error-600">*</p></span
          >
          <div class="flex flex-col w-full">
            <input
              class="w-full text-palette-gray-900 font-normal text-base rounded-lg h-11"
              [ngClass]="{ 'border-slate-300': !showError }"
              id="eventNameId"
              name="eventNameId"
              required="showError"
              [disabled]="isSubmitting"
              #eventNameId="ngModel"
              type="text"
              pInputText
              [(ngModel)]="eventName"
              (ngModelChange)="checkAllFields()"
              (input)="handleInputChange()"
              placeholder="{{ 'EVENT.ENTER_EVENT_NAME' | translate }}" />
            <div
              *ngIf="eventNameId.invalid && (eventNameId.dirty || eventNameId.touched) && showError"
              class="text-system-error-600 text-xs mt-1.5">
              {{ 'EVENT.MESSAGE_REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-1.5">
          <span class="flex font-medium text-sm text-palette-gray-700">{{ 'EVENT.HOST' | translate }}</span>
          <div
            class="flex w-full"
            [ngClass]="{
              'pointer-events-none': isSubmitting
            }">
            <p-autoComplete
              class="w-full"
              appendTo="body"
              styleClass="min-h-[44px]"
              #autoComplete
              [(ngModel)]="selectedFriends"
              (onSelect)="onFriendSelect($event)"
              (onUnselect)="onFriendUnSelect($event)"
              (completeMethod)="filterFriend($event)"
              [suggestions]="dataFriendsClone.length ? dataFriendsClone : filteredFriends"
              (click)="showDropdownFriend(autoComplete)"
              placeholder="{{ 'FANPAGE.SEARCH' | translate }}"
              field="full_name"
              (keyup)="handleInputChangeFriend($event)"
              [multiple]="true">
              <ng-template let-friend pTemplate="item">
                <div class="flex align-items-center gap-2" *ngIf="friend.user_info">
                  <img
                    [src]="getImgUrl(friend?.user_info?.avatar_thumbnail_url)"
                    class="rounded-full"
                    style="width: 20px; height: 20px" />
                  <div>{{ friend?.user_info?.full_name }}</div>
                </div>
                <div class="flex align-items-center gap-2" *ngIf="!friend?.user_info">
                  <img
                    [src]="getImgUrl(friend?.avatar_thumbnail_url)"
                    class="rounded-full"
                    style="width: 20px; height: 20px" />
                  <div>{{ friend.full_name }}</div>
                </div>
              </ng-template>
              <ng-template let-friend pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="friend.user_info">
                  <img
                    [src]="getImgUrl(friend?.user_info?.avatar_thumbnail_url)"
                    class="rounded-full"
                    style="width: 20px; height: 20px" />
                  <div>{{ friend?.user_info?.full_name }}</div>
                </div>
                <div class="flex align-items-center gap-2" *ngIf="!friend?.user_info">
                  <img
                    [src]="getImgUrl(friend?.avatar_thumbnail_url)"
                    class="rounded-full"
                    style="width: 20px; height: 20px" />
                  <div>{{ friend.full_name }}</div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
        <div class="flex flex-col gap-1.5" *ngIf="selectedOption === '2'">
          <span class="flex font-medium text-sm text-palette-gray-700"
            >{{ 'EVENT.LINK_TO_JOIN' | translate }}
            <p class="ml-1 text-system-error-600">*</p></span
          >
          <div class="flex flex-col w-full p-input-icon-left">
            <i class="sctr-icon-link-03 text-lg top-[16px]"></i>
            <input
              class="w-full text-palette-gray-900 font-normal text-base input rounded-lg h-11"
              id="eventLinkId"
              name="eventLinkId"
              required
              #eventLinkId="ngModel"
              type="text"
              pInputText
              [(ngModel)]="eventLink"
              [disabled]="isSubmitting"
              (ngModelChange)="checkAllFields()"
              (input)="handleInputChange()"
              placeholder="{{ 'EVENT.ADD_A_LINK' | translate }}" />
            <div
              *ngIf="(eventLinkId.invalid && (eventLinkId.dirty || eventLinkId.touched)) || isShowErrorEventLink"
              class="text-system-error-600 text-xs mt-1.5">
              {{ 'EVENT.MESSAGE_LINK' | translate }}
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-1.5" *ngIf="selectedOption === '1'">
          <span class="flex font-medium text-sm text-palette-gray-700"
            >{{ 'COMMON.ADDRESS' | translate }}
            <p class="ml-1 text-system-error-600">*</p></span
          >
          <div class="w-full">
            <p-autoComplete
              #addressDropdown
              styleClass="w-full max-w-full"
              inputStyleClass="w-full h-11 border border-palette-gray-300 rounded-lg placeholder:border-palette-red-300 placeholder:text-palette-gray-500"
              panelStyleClass="w-full max-w-full custom-scroll"
              field="main_text"
              appendTo="body"
              [suggestions]="addressList"
              [forceSelection]="true"
              [(ngModel)]="selectedAddress"
              [disabled]="isSubmitting"
              [placeholder]="'EVENT.ENTER_ADDRESS' | translate"
              [showClear]="!isSearchingAddress ? true : false"
              (onClear)="onChangeSearchKey(null)"
              (onSelect)="checkAllFields()"
              (onFocus)="onFocusAddress($event)"
              (completeMethod)="onChangeSearchKey($event.query)"
              (ngModelChange)="handleInputChange()">
              <ng-template let-address pTemplate="item">
                <div class="flex gap-2 items-center text-palette-gray-700">
                  <i class="sctr-icon-marker-pin-02 text-base/4"></i>
                  <div class="flex flex-col justify-center" title="{{ address.addr }}">
                    <span class="text-base">{{ address.main_text }}</span>
                    <span class="text-xs text-palette-gray-500">{{ address.addr }}</span>
                  </div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
        <div class="flex flex-col gap-1.5">
          <div class="flex w-full gap-4 md:gap-6">
            <div class="flex flex-col w-[50%] gap-2">
              <span class="flex font-medium text-sm text-palette-gray-700"
                >{{ 'EVENT.START_DATE' | translate }}
                <p class="ml-1 text-system-error-600">*</p></span
              >
              <calendar
                (datePicker)="handleCheckDatePickerStartDate($event)"
                [minStartDate]="minStartDate"
                [disabled]="isSubmitting"
                [selectedDate]="startDate"></calendar>
            </div>
            <div class="flex flex-col w-1/2 gap-2">
              <span class="flex font-medium text-sm text-palette-gray-700"
                >{{ 'EVENT.START_TIME' | translate }}
                <p class="ml-1 text-system-error-600">*</p></span
              >
              <time-picker
                class="flex"
                [(visibleTimePicker)]="startTimePickerVisibility"
                (visibleTimePickerChange)="onVisibleTimePickerChange($event, 1)"
                (pickedTime)="handleStartTime($event)"
                [disabled]="isSubmitting"
                [prefillTime]="startTime"></time-picker>
              <div *ngIf="isShowErrorTime" class="text-system-error-600 text-xs">
                {{ 'EVENT.SELECT_A_TIME_AFTER' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-1.5">
          <div class="flex w-full gap-6">
            <div class="flex flex-col w-1/2 gap-2">
              <span class="flex font-medium text-sm text-palette-gray-700"
                >{{ 'EVENT.END_DATE' | translate }}
                <p class="ml-1 text-system-error-600">*</p></span
              >
              <calendar
                (datePicker)="handleCheckDatePickerEndDate($event)"
                [selectedDate]="endDate"
                [disabled]="isSubmitting"
                [minStartDate]="startDate ? startDate : invalidDate"></calendar>
            </div>
            <div class="flex flex-col w-1/2 gap-2">
              <span class="flex font-medium text-sm text-palette-gray-700"
                >{{ 'EVENT.END_TIME' | translate }}
                <p class="ml-1 text-system-error-600">*</p></span
              >
              <time-picker
                class="flex"
                [(visibleTimePicker)]="endTimePickerVisibility"
                (visibleTimePickerChange)="onVisibleTimePickerChange($event, 2)"
                [disabled]="isSubmitting"
                (pickedTime)="handleEndTime($event)"
                [prefillTime]="endTime"></time-picker>
              <div *ngIf="isShowErrorEndTime" class="text-system-error-600 text-xs">
                {{ 'EVENT.MESSAGE_TIME_MUST' | translate }}
              </div>
              <div *ngIf="isEndTimeBeforeStartTime" class="text-system-error-600 text-xs">
                {{ 'EVENT.MESSAGE_TIME_BEFORE_START_TIME' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-1.5">
          <span class="flex font-medium text-sm text-palette-gray-700">{{ 'EVENT.EVENT_PRIVACY' | translate }}</span>
          <div
            class="flex flex-col w-full"
            [ngClass]="{
              'pointer-events-none opacity-50': groupPrivacy === 'Private' || isFanPagePublicEvent
            }">
            <p-dropdown
              appendTo="body"
              [options]="dropdownOptions"
              [(ngModel)]="privacy"
              [disabled]="isSubmitting"
              optionLabel="name"
              styleClass="w-[calc(50%-12px)] h-11"
              (ngModelChange)="handleInputChange()"
              [ngClass]="{ 'remove-arrow': groupPrivacy === 'Private' || isFanPagePublicEvent }">
              <ng-template let-option pTemplate="selectedItem" class="flex w-full custom-dropdown gap-2">
                <i class="{{ option.icon }}"></i>
                {{ option.name | translate }}
              </ng-template>
              <ng-template let-option pTemplate="item" class="flex w-full custom-dropdown gap-2">
                <i class="{{ option.icon }}"></i>
                {{ option.name | translate }}
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <div class="flex flex-col gap-1.5">
          <span class="flex font-medium text-sm text-palette-gray-700">{{ 'COMMON.DESCRIPTION' | translate }}</span>
          <div class="flex flex-col w-full">
            <textarea
              class="h-[90px] overflow-y-auto rounded-lg"
              rows="5"
              cols="30"
              pInputTextarea
              [autoResize]="true"
              [(ngModel)]="textareaValue"
              [disabled]="isSubmitting"
              (input)="handleInputChange()"
              placeholder="{{ 'EVENT.ENTER_DESCRIPTION' | translate }}"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex gap-3 justify-end items-center">
      <ng-container
        [ngTemplateOutlet]="CTAButtons"
        [ngTemplateOutletContext]="{
          isWidthFull: false
        }"></ng-container>
    </div>
  </ng-template>
</p-dialog>

<ng-template #CTAButtons let-isWidthFull="isWidthFull">
  <div class="w-full flex md:justify-end gap-3">
    <button
      class="custom-button bg-palette-base-white hover:bg-palette-gray-200 border-palette-gray-300 text-palette-gray-700 disabled:bg-palette-gray-100 disabled:text-palette-gray-300"
      [disabled]="isSubmitting"
      (click)="visible = false">
      {{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}
    </button>
    <button
      class="custom-button bg-palette-blue-600 hover:bg-palette-blue-700 disabled:bg-palette-blue-200 border-palette-blue-600 disabled:border-palette-blue-200 text-palette-base-white"
      type="submit"
      [disabled]="oldDataEvent ? !isEditButtonEnabled || isSubmitting : disableEventAction()"
      (click)="handleActionEvent()">
      <i *ngIf="isSubmitting" class="sctr-icon-loading-02 animate-spin text-xl/5"></i>
      <span *ngIf="!isSubmitting">{{
        (oldDataEvent ? 'COMMON.BUTTON_ACTION.EDIT' : 'COMMON.BUTTON_ACTION.CREATE') | translate
      }}</span>
      <span *ngIf="isSubmitting">{{ 'COMMON.BUTTON_ACTION.SAVING' | translate }}</span>
    </button>
  </div>
</ng-template>
