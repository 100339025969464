<div class="create-group-fanpage-dialog">
  <p-dialog
    class="custom-scroll"
    styleClass="w-[500px] border border-palette-gray-200 rounded-xl {{
      groupStyle ? 'bg-group-gradient' : 'bg-fanpage-gradient'
    }}"
    contentStyleClass="border-0 p-6 pt-0 md:pt-6 overflow-visible rounded-xl bg-transparent overflow-y-auto"
    [(visible)]="visible"
    [draggable]="false"
    [resizable]="false"
    [modal]="true"
    [dismissableMask]="true"
    (onShow)="onShowDialog()"
    (onHide)="onCloseDialog()">
    <ng-template pTemplate="header" class="rounded-t-xl">
      <span class="text-lg text-palette-gray-900 font-semibold">{{
        (groupStyle ? 'PROFILE.GROUP.CREATE_GROUP' : 'FANPAGE.CREATE_FAN_PAGE.CREATE_PAGE') | translate
      }}</span>
    </ng-template>
    <ng-container [ngTemplateOutlet]="dialogContent"></ng-container>
  </p-dialog>
</div>

<warning-dialog
  *ngIf="visibleDialogConfirm && !isLoading && !isSubmitSuccess"
  [visibleDialogConfirm]="visibleDialogConfirm"
  [visible]="visible"
  [resetForm]="resetForm.bind(this)"
  [titleHeader]="getTitleHeaderDialog()"
  [description]="getDescriptionDialog()"
  (keepEditing)="onKeepEditing()">
</warning-dialog>

<ng-template #dialogContent>
  <div class="p-[1px] rounded-xl bg-gradient-to-b from-palette-gray-200 to-white">
    <div class="p-6 bg-white rounded-xl flex flex-col gap-8">
      <form class="flex flex-col gap-4 text-sm font-medium text-palette-gray-700" [formGroup]="formGroup">
        <div class="flex flex-col gap-1.5">
          <label class="text-sm">
            {{ (groupStyle ? 'PROFILE.GROUP.GROUP_NAME' : 'FANPAGE.CREATE_FAN_PAGE.FANPAGE_NAME') | translate }}
          </label>
          <input
            #inputName
            type="text"
            pInputText
            class="create-group-fanpage-item py-2.5 px-3.5 read-only:cursor-default read-only:bg-palette-gray-200"
            formControlName="name"
            [readOnly]="isLoading"
            [maxlength]="fanpageNameMaxLength"
            placeholder="{{
              (groupStyle ? 'PROFILE.GROUP.ENTER_A_NAME' : 'FANPAGE.OVERVIEW.ENTER_FANPAGE_NAME') | translate
            }}"
            (blur)="onBlurInput(inputName.value, name)" />
          <span *ngIf="name?.errors && name?.touched" class="text-palette-red-600">
            <span *ngIf="name?.errors?.['required']">
              {{
                'ERROR.COMMON.REQUIRED_FIELD'
                  | translate
                    : {
                        fieldName:
                          (groupStyle ? 'PROFILE.GROUP.GROUP_NAME' : 'FANPAGE.CREATE_FAN_PAGE.FANPAGE_NAME') | translate
                      }
              }}.
            </span>
            <span *ngIf="name?.errors?.['minlength']">
              {{ 'ERROR.COMMON.INVALID_MINIMUM_LENGTH' | translate: { number: fanpageNameMinLength } }}.
            </span>
          </span>
        </div>

        <div *ngIf="groupStyle" class="flex flex-col gap-1.5 custom-dropdown">
          <label class="text-sm">
            {{ 'PROFILE.GROUP.CHOOSE_PRIVACY' | translate }}
          </label>
          <p-dropdown
            styleClass="rounded-lg w-full h-11 flex items-center {{ isLoading ? 'bg-palette-gray-200' : 'bg-white' }}"
            panelStyleClass="p-1.5 rounded-lg"
            formControlName="privacy"
            optionLabel="label"
            [options]="groupPrivacyOptions"
            [(ngModel)]="selectedPrivacy">
            <ng-template let-option pTemplate="selectedItem">
              <div class="flex gap-2 items-center text-palette-gray-900" *ngIf="selectedPrivacy">
                <i class="{{ option.icon }}"></i>
                {{ 'PRIVACY.' + option.label.toUpperCase() | translate }}
              </div>
            </ng-template>
            <ng-template let-privacyItem pTemplate="item">
              <div class="flex items-center justify-between w-full">
                <div class="flex gap-2 items-center text-base font-medium text-palette-gray-900">
                  <i class="{{ privacyItem.icon }}"></i>
                  <span>{{ 'PRIVACY.' + privacyItem.label.toUpperCase() | translate }}</span>
                </div>
                <i
                  id="check-icon"
                  class="sctr-icon-check text-palette-blue-600"
                  *ngIf="selectedPrivacy.label === privacyItem.label"></i>
              </div>
            </ng-template>
          </p-dropdown>
        </div>

        <div *ngIf="!groupStyle" class="flex flex-col gap-1.5">
          <label class="text-sm">
            {{ 'FANPAGE.CREATE_FAN_PAGE.CATEGORY' | translate }}
          </label>
          <ng-container
            [ngTemplateOutlet]="selectFanpageCategory"
            [ngTemplateOutletContext]="{ formControl: categories }"></ng-container>
        </div>

        <ng-container [ngTemplateOutlet]="inviteFriendContainer"></ng-container>

        <div class="flex gap-2">
          <p-checkbox formControlName="notification" inputId="notificationCheckbox" [binary]="true"></p-checkbox>
          <label [ngClass]="{ 'cursor-pointer': !isLoading }" for="notificationCheckbox">{{
            'PROFILE.CREATE_' + (groupStyle ? 'GROUP' : 'FANPAGE') + '.SEND_NOTIFICATION_CHECKLIST' | translate
          }}</label>
        </div>
      </form>
      <button
        class="cta-button w-full flex items-center justify-center gap-2"
        (click)="onSubmit()"
        [disabled]="formGroup.invalid || isLoading">
        <i *ngIf="isLoading" class="sctr-icon-loading-02 animate-spin text-xl/5"></i>
        {{ (groupStyle ? 'PROFILE.GROUP.CREATE_GROUP' : 'FANPAGE.CREATE_FAN_PAGE.CREATE_PAGE') | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #loadingItem let-widthSkeleton="widthSkeleton">
  <div class="flex gap-2 items-center px-2.5 py-2">
    <p-skeleton shape="circle" size="24px"></p-skeleton>
    <p-skeleton [width]="widthSkeleton ? widthSkeleton : '12rem'" height="20px"></p-skeleton>
  </div>
</ng-template>

<ng-template #selectedFriendList>
  <div *ngIf="selectedFriendsTemp.length" class="overflow-y-scroll max-h-20">
    <div class="flex gap-2 flex-wrap">
      <p-chip
        *ngFor="let friend of selectedFriendsTemp"
        [title]="friend.full_name"
        [label]="utilityService.shortenName(friend.full_name, 26)"
        [image]="getImgUrl(friend.avatar_thumbnail_url)"
        [removable]="!isLoading"
        styleClass="custom-chip {{ isLoading ? 'bg-palette-gray-200 pr-6' : 'hover:bg-palette-gray-200' }}"
        removeIcon="sctr-icon-x-close mr-2 ml-1 text-palette-gray-400"
        (onRemove)="onRemoveSelectedFriend(friend)">
      </p-chip>
    </div>
  </div>
</ng-template>

<ng-template #noAvailableFriends>
  <div class="w-full p-4 flex flex-col justify-center items-center gap-2">
    <div
      class="h-14 w-14 bg-palette-gray-100 border-8 border-palette-gray-50 rounded-full flex items-center justify-center">
      <i class="sctr-icon-users-01 text-2xl font-medium text-palette-gray-600"></i>
    </div>
    <span>{{ 'FRIEND.NO_FRIENDS' | translate }}</span>
  </div>
</ng-template>

<ng-template #showFriendList>
  <ng-container *ngIf="inviteFriendList.length; else noAvailableFriends">
    <div class="w-full px-2.5 py-2 flex gap-2 select-none">
      <div class="w-6 h-6 flex items-center justify-center">
        <p-checkbox [binary]="true" [(ngModel)]="multipleChanges" (onChange)="onMultipleChanges()"></p-checkbox>
      </div>
      <span class="text-palette-gray-500">
        {{ 'INVITE_FRIENDS.SELECTED' | translate }} ({{ selectedFriends.length }})
      </span>
    </div>
    <div
      scroll
      (scrolledToBottom)="onScrolledToBottom()"
      class="flex flex-col gap-1 max-h-48 overflow-y-auto"
      [ngClass]="{ 'pr-1.5': inviteFriendList && inviteFriendList.length > 4 }">
      <ng-container *ngFor="let friend of inviteFriendList; index as index">
        <div
          (click)="onSelectedFriend(friend, index)"
          class="flex justify-between rounded-md hover:bg-palette-gray-200 transition-colors cursor-pointer select-none px-2.5 py-2"
          [ngClass]="{ 'bg-palette-blue-200 hover:bg-palette-blue-300': friendCheckboxes[index] }">
          <div class="flex gap-2 items-center">
            <img
              [src]="getImgUrl(friend?.avatar_thumbnail_url)"
              class="w-6 h-6 rounded-full border border-palette-gray-100" />
            <span [title]="friend?.full_name" class="text-palette-gray-900 font-medium max-w-[150px] truncate">
              {{ friend?.full_name }}
            </span>
            <span [title]="'@' + friend?.username" class="text-palette-gray-600 max-w-[120px] truncate">
              @{{ friend?.username }}
            </span>
          </div>
          <i *ngIf="friendCheckboxes[index]" class="sctr-icon-check text-palette-blue-600 text-xl/5"></i>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoadingMoreFriends" [ngTemplateOutlet]="loadingItem"> </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #inviteFriendContainer>
  <div class="flex flex-col gap-1.5">
    <label class="text-sm">{{ 'COMMON.INVITE_FRIENDS' | translate }}</label>
    <span class="p-input-icon-left h-11 w-full">
      <i class="sctr-icon-search-lg text-lg text-palette-gray-500 leading-none"></i>
      <input
        #searchFriends
        pInputText
        type="text"
        class="create-group-fanpage-item pl-9 disabled:bg-palette-gray-200"
        [disabled]="isLoading"
        placeholder="{{ 'INVITE_FRIENDS.SEARCH' | translate }}"
        [(ngModel)]="searchKeyword"
        (focus)="friendListPanel.show($event)"
        (keyup)="onChangeSearchFriends(searchFriends.value); friendListPanel.show($event)" />
    </span>
    <p-overlayPanel
      #friendListPanel
      styleClass="before:content-[none] after:content-[none] w-[400px] max-w-[400px] mt-1 rounded-lg border border-palette-gray-300 shadow-sm custom-scroll"
      (onHide)="onHideOverlayPanel()">
      <ng-template pTemplate="content">
        <div class="-my-3 -mx-4 px-1">
          <ng-container *ngIf="isLoadingFriends; else showFriendList">
            <div class="flex flex-col gap-2">
              <ng-container [ngTemplateOutlet]="loadingItem" [ngTemplateOutletContext]="{ widthSkeleton: '8rem' }">
              </ng-container>
              <ng-container [ngTemplateOutlet]="loadingItem" [ngTemplateOutletContext]="{ widthSkeleton: '14rem' }">
              </ng-container>
              <ng-container [ngTemplateOutlet]="loadingItem" [ngTemplateOutletContext]="{ widthSkeleton: '10rem' }">
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </p-overlayPanel>
    <ng-container *ngTemplateOutlet="selectedFriendList"></ng-container>
  </div>
</ng-template>

<ng-template #categoryChip let-item="item">
  <p-chip
    [title]="item.category_name"
    [label]="item.category_name"
    [removable]="!isLoading"
    styleClass="custom-chip pl-2 pr-0 {{ isLoading ? 'bg-palette-gray-200 pr-6' : 'hover:bg-palette-gray-200' }}"
    removeIcon="sctr-icon-x-close mr-2 ml-1 text-palette-gray-400"
    (onRemove)="onRemoveSelectedCategory(item)">
  </p-chip>
</ng-template>

<ng-template #selectFanpageCategory let-formControl="formControl">
  <p-multiSelect
    class="custom-multiselect"
    appendTo="body"
    styleClass="w-full min-h-[44px] rounded-lg flex items-center {{ isLoading ? 'bg-palette-gray-200' : 'bg-white' }}"
    panelStyleClass="p-1.5 rounded-lg"
    display="chip"
    optionLabel="category_name"
    [options]="fanpageService.translatedCategoryList"
    [(ngModel)]="selectedCategories"
    [selectionLimit]="3"
    [virtualScroll]="true"
    [virtualScrollItemSize]="44"
    [filter]="true"
    [resetFilterOnHide]="true"
    [defaultLabel]="'FANPAGE.OVERVIEW.ENTER_SELECT_CATEGORY' | translate"
    [emptyFilterMessage]="'COMMON.NO_RESULTS_FOUND' | translate"
    [emptyMessage]="'COMMON.NO_RESULTS_FOUND' | translate"
    [formControl]="formControl">
    <ng-template let-categories pTemplate="selectedItems">
      <ng-container *ngIf="!categories || !categories.length; else showSelectedItems">
        <span class="text-palette-gray-500">{{ 'FANPAGE.OVERVIEW.ENTER_SELECT_CATEGORY' | translate }}</span>
      </ng-container>
      <ng-template #showSelectedItems>
        <ng-container *ngIf="categories && categories.length === 1; else showQuantity">
          <ng-container
            [ngTemplateOutlet]="categoryChip"
            [ngTemplateOutletContext]="{ item: categories[0] }"></ng-container>
        </ng-container>
        <ng-template #showQuantity>
          <div>{{ 'COMMON.FORM.CATEGORIES_SELECTED' | translate: { quantity: categories.length } }}</div>
        </ng-template>
      </ng-template>
    </ng-template>
    <ng-template let-category pTemplate="item">
      <div [title]="category.category_name" class="flex align-items-center gap-2">
        <span class="text-palette-gray-900">{{ category.category_name }}</span>
      </div>
    </ng-template>
  </p-multiSelect>
  <span
    *ngIf="categories?.errors && categories?.touched && categories?.errors?.['required']"
    class="text-palette-red-600">
    {{ 'ERROR.COMMON.REQUIRED_FIELD' | translate: { fieldName: 'COMMON.FORM.CATEGORY' | translate } }}
  </span>
  <ng-container *ngIf="selectedCategories && selectedCategories.length > 1">
    <div class="w-full flex flex-wrap gap-1">
      <ng-container
        *ngFor="let item of selectedCategories"
        [ngTemplateOutlet]="categoryChip"
        [ngTemplateOutletContext]="{ item }"></ng-container>
    </div>
  </ng-container>
</ng-template>
