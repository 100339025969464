<p-dialog
  [(visible)]="visibleDialogConfirm"
  [style]="{ 'width': '610px' }"
  class="custom-dialog discard-dialog"
  styleClass="border border-palette-gray-200 rounded-xl bg-white"
  contentStyleClass="border-0 py-0 px-6"
  [draggable]="false"
  [resizable]="false"
  [modal]="true">
  <ng-template pTemplate="header">
    <h1 class="text-lg font-semibold text-palette-gray-900">{{ titleHeader | translate }}</h1>
  </ng-template>
  <span class="text-sm text-palette-gray-700">
    {{ description | translate }}
  </span>
  <ng-template pTemplate="footer">
    <div class="w-full flex gap-3 justify-end">
      <button
        pButton
        (click)="confirmDialog(confirmCloseActions.discard)"
        class="text-palette-gray-900 bg-white border-palette-gray-300 hover:bg-palette-gray-100">
        {{ 'COMMON.BUTTON_ACTION.DISCARD' | translate }}
      </button>
      <button
        pButton
        (click)="confirmDialog(confirmCloseActions.keepEditing)"
        class="text-white bg-palette-blue-600 border-palette-blue-600 hover:bg-palette-blue-700 hover:border-palette-blue-700">
        {{ 'COMMON.BUTTON_ACTION.KEEP_EDITING' | translate }}
      </button>
    </div>
  </ng-template>
</p-dialog>
