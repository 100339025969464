export interface UserBadge {
  type: string,
  icon: string,
  color: string,
  label: string
}

export const BRAND_SOCTRIP_STAR = [
    {
      type: 'DIAMOND',
      icon: 'assets/icons/badge/diamod_star.svg',
      color: 'bg-purple-500',
      label: 'Diamond Star'
    },
    {
      type: 'GOLD',
      icon: 'assets/icons/badge/gold_star.svg',
      color: 'bg-yellow-400',
      label: 'Gold Star'
    },
    {
      type: 'SILVER',
      icon: 'assets/icons/badge/silver_star.svg',
      color: 'bg-blue-600',
      label: 'Silver Star'
    },
    {
      type: 'BRONZE',
      icon: 'assets/icons/badge/bronze_star.svg',
      color: 'bg-gray-400',
      label: 'Bronze Star'
    }
];

export const BRAND_SOCTRIP_MASTER = [
    {
      type: 'DIAMOND',
      icon: 'assets/icons/badge/diamod_master.svg',
      color: 'bg-cyan-400',
      label: 'Diamond Master'
    },
    {
      type: 'GOLD',
      icon: 'assets/icons/badge/gold_master.svg',
      color: 'bg-yellow-400',
      label: 'Gold Master'
    },
    {
      type: 'SILVER',
      icon: 'assets/icons/badge/silver_master.svg',
      color: 'bg-gray-400',
      label: 'Silver Master'
    },
    {
      type: 'BRONZE',
      icon: 'assets/icons/badge/bronze_master.svg',
      color: 'bg-amber-500',
      label: 'Bronze Master'
    }
];