export const environment = {
  production: false,
  APP_NAME: 'TM Travel',
  COMPANY_NAME: 'TMA Technology Group',
  baseURL: 'https://api-dev-tmtrav.tma-swerp.com',
  SOCIAL_APP_URL: 'https://tmtrav-dev.tma-swerp.com',
  TRAVEL_ASSIST_ORIGIN: 'https://tmtrav-travel-dev.tma-swerp.com',
  TRAVEL_ASSIST_URL: 'https://tmtrav-travel-dev.tma-swerp.com/tour',
  BOOKING_URL: 'https://tmtrav-booking-dev.tma-swerp.com',
  ECOMMERCE_FOOD_URL: 'https://tmtrav-food-dev.tma-swerp.com',
  ECOMMERCE_URL: 'https://tmtrav-ecommerce-dev.tma-swerp.com',
  ECOMMERCE_FOOD_MGMT_URL: 'https://tmtrav-food-management-dev.tma-swerp.com',
  ECOMMERCE_MGMT_URL: 'https://tmtrav-ecommerce-management-dev.tma-swerp.com',
  ECOMMERCE_MEGALIVE_URL: 'https://mega-live.tma-swerp.com',
  ECOMMERCE_MEGALIVE_CENTRE_URL: 'https://mega-live-centre.tma-swerp.com',
  ECOMMERCE_HC_SPECIAL_SELLER_URL: 'https://home-connect-special-seller.tma-swerp.com',
  ECOMMERCE_HC_SUPPLIER_URL: 'https://home-connect-supplier.tma-swerp.com',
  ECOMMERCE_MARKETING: 'https://ecommerce-marketing.tma-swerp.com',
  CAR_RENTAL_ORIGIN: 'https://tmtrav-car-rental-dev.tma-swerp.com',
  CAR_RENTAL_URL: 'https://tmtrav-car-rental-dev.tma-swerp.com/booking/car',
  FLIGHT_URL: 'https://tmtrav-flight-dev.tma-swerp.com',
  SOCTRIP_CENTER_URL: 'https://tmtrav-business-central-dev.tma-swerp.com',
  CONTENT_APPROVAL_URL: 'https://approval-tmtrav-dev.tma-swerp.com',
  AFFILIATE_URL: 'https://tmtrav-affiliate-dev.tma-swerp.com',
  MY_WALLET_URL: 'https://my-wallet-dev.tma-swerp.com',
  VOUCHER_HUB_URL: 'https://tmtrav-voucher-hub-dev.tma-swerp.com',
  MARKETING_HUB: 'https://marketing-hub.tma-swerp.com',
  FLIGHT_ADMIN_URL: 'https://flight-admin-tmtrav.tma-swerp.com',
  HOTEL_ADMIN_URL: 'https://hotel-admin-tmtrav.tma-swerp.com',
  PAYMENT_URL: 'https://payment-dev.tma-swerp.com',
  APP_DEBUG_URL: 'http://localhost:4200',
  SOCIAL_APP_DEEPLINK: 'stagingsoctrip://',
  TOKEN_KEY: 'access_token',
  OWNER_USER_ID: 'owner_user_id',
  REFRESH_TOKEN_KEY: 'refresh_token',
  USER_ROLE_KEY: 'user_role',
  REFRESH_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  ACCESS_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  AUTH_STATUS: 'auth_status',
  USER_PROFILE: 'user_profile',
  firebase: {
    apiKey: 'AIzaSyDDl3R28XfWQoJzWaPhf3WCLygQy6BhdyQ',
    authDomain: 'soctrip-app.firebaseapp.com',
    projectId: 'soctrip-app',
    storageBucket: 'soctrip-app.appspot.com',
    messagingSenderId: '179622932533',
    appId: '1:179622932533:web:44c83b97f8aacd5fa1c17c',
    measurementId: 'G-B71N7T3E0W'
  },
  languages: ['en', 'vi'],
  socialAuthenIds: {
    google: '802332645501-0aiir74m36114t6v974bcrg4jrf8k1do.apps.googleusercontent.com',
    facebook: '2619489051654463'
  },
  API_TIMEOUT: 30000,
  ADMIN_CONTACT_EMAIL: 'tmadmin@rover.info',
  ANDROID_DOWNLOAD: 'https://play.google.com/store/apps/details?id=com.soctrip.social',
  IOS_DOWNLOAD: 'https://testflight.apple.com/join/6RARZ3xh',
  FILE_SIZE_LIMITS: {
    default: 5242880, //5MB
    image: 10485760, //10MB
    video: 209715200, //200MB
    pdf: 5242880
  },
  AD_BUFFER_SIZE_LIMIT: 1024, //1MB
  URL_WHITELIST: ['https://rtc.dayconyeu.com'],
  GA_CODE: 'G-PQBCBB9BDP'
};
